import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.sass']
})
export class SigninComponent implements OnInit {

  signinForm!: FormGroup;
  inProgress: boolean = false;
  loginFailed: boolean = false;

  constructor(private api: ApiService, private auth: AuthService, private router: Router,private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.signinForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
      ]),
      rememberMe: new FormControl('', {})
    });
  }

  onSignin(): void {

    this.spinner.showSpinner();

    this.inProgress = true;
    this.loginFailed = false;

    const loginData = {
      username: this.signinForm.value.email,
      password: this.signinForm.value.password,
      remember: this.signinForm.value.rememberMe
    };

    this.api.postTypeRequest('/account/signin', loginData).subscribe((res: any) => {

      if (res.token) {
        this.auth.setDataInLocalStorage('token', res.token)
        //TODO: Later, refactor this to navigate account route and
        // update auth service to emit an event for subscribers to
        // take necessary actions e.g changing buttons and UI...
        this.spinner.hideSpinner();

        this.router.navigate(['/hesabim']);
      }
    }, err => {
      this.inProgress = false;
      if (err.status == 404){
        if (err.error.errors || err.error.errors[0] == "No users found"){
          this.loginFailed = true;
        }
      }
    });
  }

  get email() {
    return this.signinForm.get('email');
  }

  get password() {
    return this.signinForm.get('password');
  }

}
