import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

declare var $: any;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.sass']
})
export class AccountComponent implements OnInit {

  name: string = "";
  email: string = "";
  verified: boolean = false;
  sites: any[] = [];

  newSiteName: string = "";

  profileForm!: FormGroup;

  constructor(private api: ApiService, private auth: AuthService) { }

  ngOnInit(): void {

    const siteAdded = localStorage.getItem("newSiteAdded")
    if (siteAdded && siteAdded != '') {
      this.newSiteName = siteAdded;
      $("#confirmation-modal").modal("show");
      localStorage.removeItem("newSiteAdded");
    }


    this.api.getTypeRequest('/account').subscribe((res: any) => {
      this.name = res.account[0].Name;
      this.email = res.account[0].Email;
      this.verified = res.account[0].Verified.data[0];
      this.sites = res.sites;

      console.log(this.sites);

      this.profileForm = new FormGroup({
        email: new FormControl(this.email, [
          Validators.required,
          Validators.email
        ]),
        name: new FormControl(this.name, [
          Validators.required,
        ])
      });

    }, err => {
      console.log(err);
    });
  }

  onProfileUpdate(): void {
    console.log("Update profile", this.profileForm.value);
  }

}
