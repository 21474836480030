import { ThrowStmt } from '@angular/compiler';
import { Component , Renderer2} from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpinnerService } from './services/spinner.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'kd-web';

  showSpinner = false;

  constructor(private spinnerService: SpinnerService, private renderer: Renderer2) {
      this.spinnerService.spinner$.subscribe((data: boolean) => {
        setTimeout(() => {
          if (data) {
            this.renderer.removeClass(document.body, 'loaded');
          }else{
            this.renderer.addClass(document.body, 'loaded');
          }
        });
      });
  }

  ngOnInit() {
  }

}
