<!--CONTACT FORM-->
<section id="form-section" class="home-contact-form">
    <div class="container">
        <div class="row section-title justify-content-center text-center">
            <div *ngIf="messageSent" class="col-md-9 col-lg-8 col-xl-7">
                <img src="assets/img/mail-sent-icon.png" alt="mail sent" width="214" class="mb-4">
                <h3 class="display-4">Talebiniz gönderildi.</h3>
                <div class="lead">En kısa sürede sizinle iletişime geçilecektir.<br>İlginiz için teşekkür eder, iyi
                    günler dileriz.</div>
                <a (click)="resetForm()">ANASAYFAYA DÖN</a>
            </div>
            <div *ngIf="!messageSent" class="col-md-9 col-lg-8 col-xl-7">
                <h3 class="display-4">Bizimle İletişime Geçin</h3>
                <div class="lead">Kudyu Platformunu kullanmaya başlamak için bize mesaj atın, işinizi bugünden büyütmek
                    için sizinle hemen temasa geçelim.</div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-7" id="hubspotForm">
                <!--<form class="d-flex flex-column" [formGroup]="contactForm" (ngSubmit)="onSend()">
                    <input class="form-control h-100" type="text" name="getstartedname" formControlName="getstartedname" placeholder="Ad Soyad">
                    <input class="form-control h-100 my-3" type="email" name="getstartedemail" formControlName="getstartedemail" placeholder="E-Posta">
                    <input class="form-control h-100" type="text" name="getstartedphone" formControlName="getstartedphone" placeholder="Telefon">
                    <input class="form-control h-100 my-3" type="text" name="getstartedcompany" formControlName="getstartedcompany" placeholder="Şirket Adı (opsiyonel)">
                    <textarea rows="6" class="form-control h-100" type="text" name="getstartednotes" formControlName="getstartednotes" placeholder="Bize projenizden bahsedebilirsiniz."></textarea>
                    <button [disabled]="!contactForm.valid && contactForm.touched" class="btn btn-secondary flex-shrink-0 my-5" type="submit">
                        {{buttonText}}
                    </button>
                </form>-->
            </div>
        </div>
    </div>
</section>