<!--SUPPORT BOX-->
<section class="bg-light o-hidden support-box">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-md-9 col-lg-6 mb-4 mb-md-5 mb-lg-0 text-center text-lg-left" data-aos="fade-right">
                <h3 class="h1">Her adımda ihtiyacınız olan yardımı alın</h3>
                <p class="lead">Ne zaman yardıma ihtiyacınız olsa yanınızdayız. Teknik veya pazarlama konularında destek almak için bize yazın.</p>
                <a [routerLink]="['/iletisim']" fragment="form-section" class="hover-fade-out need-support-text">
                  DESTEĞE Mİ İHTİYACINIZ VAR
                </a>
            </div>
            <div class="col-md-7 col-lg-6 col-xl-4">
                <div class="card h-100 shadow-lg hover-box-shadow">
                    <img class="card-img hover-fade-out" src="assets/img/support-1.jpg" alt="blog.5.image">
                </div>
            </div>
        </div>
    </div>
</section>
