import { AbstractControl, ValidatorFn, FormGroup } from "@angular/forms"

export function strengthValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const passRe = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        const enough = passRe.test(control.value);
        return enough ? null : { strength: 'not enough' };
    };
}

export function matchValidator(orgControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        return control.value === orgControl.value ? null : { match: 'not match' };
    };
}