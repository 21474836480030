<app-slider></app-slider>

<!--SLIDER BOTTOM-->
<section class="bg-white o-hidden slider-bottom">
    <div class="container">
        <div class="row section-title justify-content-center text-center">
            <div class="col-md-9 col-lg-9 col-xl-9">
                <h2 class="display-4">Satışa Başlayın, İşinizi Büyütün.</h2>
                <div class="lead">Online mağazanızı dakikalar içerisinde açın, hemen satış yapmaya başlayın.</div>
            </div>
        </div>
        <div class="row align-items-center justify-content-around">
            <div class="col-md-9 col-lg-5" data-aos="fade-in">
                <img src="assets/img/index-template.gif" alt="Image" class="img-fluid rounded">
            </div>
            <div class="col-md-9 col-lg-6 col-xl-5 mt-4 mt-md-5 mt-lg-0">
                <ol class="list-unstyled p-0">
                    <li class="d-flex align-items-start my-4 my-md-5">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-success">
                            <div class="position-absolute text-white h5 mb-0">1</div>
                        </div>
                        <div class="ml-3 ml-md-4">
                            <h4>Sitenizi açın</h4>
                            <p>
                                Ürün veya hizmetlerinizi online satmak için
                                <br/> e-ticaret sitenizi açın veya Kudyu 'ya taşıyın.
                            </p>
                        </div>
                    </li>
                    <li class="d-flex align-items-start my-4 my-md-5">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-success">
                            <div class="position-absolute text-white h5 mb-0">2</div>
                        </div>
                        <div class="ml-3 ml-md-4">
                            <h4>Satışa hemen başlayın</h4>
                            <p>
                                Sitenizi açar açmaz satışa hemen başlayabileceğiniz hazır bir siteniz olacak. Entegrasyonları tanımlayın ve satışa geçin.
                            </p>
                        </div>
                    </li>
                    <li class="d-flex align-items-start my-4 my-md-5">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-success">
                            <div class="position-absolute text-white h5 mb-0">3</div>
                        </div>
                        <div class="ml-3 ml-md-4">
                            <h4>İşinizi büyütün</h4>
                            <p>
                                Premium tasarımlar, farklı ödeme seçenekleri, gelişkin e-ticaret altyapısı ile dijital pazarlama ile işinizi büyütün.
                            </p>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</section>

<!--FEATURES-->
<section class="bg-primary-3 text-white features pb-0">
    <div class="container">
        <div class="row section-title justify-content-center text-center">
            <div class="col-md-9 col-lg-8 col-xl-8">
                <h3 class="display-4">Güvenilir, Esnek ve Yeni. Kudyu karşınızda!</h3>
                <div class="lead text-white-50">
                    Kudyu size e-ticarete hemen başlamanız ve müşterilerinize ulaşmak için gelişkin bir altyapı sunar. İhtiyacınız olan altyapıyı birbirinden güzel tasarımlarla, %100 mobil uyumlu kullanmak için Kudyu'yu hemen deneyin. </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col d-flex flex-wrap justify-content-center">
                <div class="m-2 w-25 text-dark">
                    <div class="media px-5 py-3 bg-dark-alt justify-content-center">
                        <h5 class="mb-0 text-white">Çok Hızlı</h5>
                    </div>
                </div>
                <div class="m-2 w-25 text-dark">
                    <div class="media px-5 py-3 bg-dark-alt justify-content-center">
                        <h5 class="mb-0 text-white">Mobil Uyumlu</h5>
                    </div>
                </div>
                <div class="m-2 w-25 text-dark">
                    <div class="media px-5 py-3 bg-dark-alt justify-content-center">
                        <h5 class="mb-0 text-white">Çok Dilli</h5>
                    </div>
                </div>
                <div class="m-2 w-25 text-dark">
                    <div class="media px-5 py-3 bg-dark-alt justify-content-center">
                        <h5 class="mb-0 text-white">7/24 Teknik Destek</h5>
                    </div>
                </div>
                <div class="m-2 w-25 text-dark">
                    <div class="media px-5 py-3 bg-dark-alt justify-content-center">
                        <h5 class="mb-0 text-white">30+ Profesyonel Tema</h5>
                    </div>
                </div>
                <div class="m-2 w-25 text-dark">
                    <div class="media px-5 py-3 bg-dark-alt justify-content-center">
                        <h5 class="mb-0 text-white">%100 SEO Uyumlu</h5>
                    </div>
                </div>
                <div class="m-2 w-25 text-dark">
                    <div class="media px-5 py-3 bg-dark-alt justify-content-center">
                        <h5 class="mb-0 text-white">SSL Sertifikası</h5>
                    </div>
                </div>
                <div class="m-2 w-25 text-dark">
                    <div class="media px-5 py-3 bg-dark-alt justify-content-center">
                        <h5 class="mb-0 text-white">Kontrol Paneli</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--SAMPLE SITES-->
<section class="bg-primary-3 text-white o-hidden sample-sites">
    <div class="highlight-selected" data-flickity='{ "imagesLoaded": true, "wrapAround":true, "pageDots":false, "adaptiveHeight":true, "autoPlay":false, "draggable":false, "prevNextButtons":false }'>
        <div class="carousel-cell col-xl-7 col-md-8">
            <div class="row align-items-center justify-content-start justify-content-sm-center mx-3 mx-xl-4">
                <img src="/assets/img/case-studies/sample-site1@2x.jpg" alt="Sample Site" class="img-fluid">
            </div>
        </div>
        <div class="carousel-cell col-xl-7 col-md-8">
            <div class="row align-items-center justify-content-start justify-content-sm-center mx-3 mx-xl-4">
                <img src="/assets/img/case-studies/sample-site3@2x.jpg" alt="Sample Site" class="img-fluid">
            </div>
        </div>
        <div class="carousel-cell col-xl-7 col-md-8">
            <div class="row align-items-center justify-content-start justify-content-sm-center mx-3 mx-xl-4">
                <img src="/assets/img/case-studies/sample-site2@2x.jpg" alt="Sample Site" class="img-fluid">
            </div>
        </div>
    </div>
    <div class="w-100 text-center made-with-kudyu-text">
      made with kudyu
    </div>
    <div class="position-absolute w-50 h-100 top right" data-jarallax-element="100 48">
        <div class="w-100 h-100 top right opacity-50"></div>
    </div>

</section>

<!--PROPERTIES-->
<section class="o-hidden props">
    <div class="container">
        <div class="row align-items-center justify-content-around text-center text-lg-left">
            <div class="col-md-9 col-lg-6 col-xl-4 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
                <div>
                    <h3 class="h1">Her yerde her zaman</h3>
                    <p class="lead">
                        Mobil öncelikli tasarımları ve yazılım alt yapısı ile her bir müşterinizin en yakınında olun. Kudyu ile müşterilerinize her zaman temas edeceksiniz.
                    </p>
                </div>
            </div>
            <div class="col-md-9 col-lg-6 col-xl-6">
                <img src="assets/img/props-1@2x.jpg" alt="Image" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<section class="o-hidden pt-0 props">
    <div class="container">
        <div class="row align-items-center justify-content-around text-center text-lg-left">
            <div class="col-md-9 col-lg-6 col-xl-4 mb-4 mb-md-5 mb-lg-0 order-lg-2 pl-lg-5 pl-xl-0">
                <div>
                    <h3 class="h1">Satışlarınızı katlayın</h3>
                    <p class="lead">
                        Satışlarınızı bugün arttırmak için Kudyu'ya geçin. Kudyu E-ticaret Platformu satışlarınızı arttırmanız için müşterilerinize ulaşacak çekici arayüzleri ve kusursuz kullanıcı deneyimini amaçlar.
                    </p>
                </div>
            </div>
            <div class="col-md-9 col-lg-6 col-xl-6 order-lg-1">
                <img src="assets/img/props-2@2x.jpg" alt="Image" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<app-support-box></app-support-box>

<app-contact-form></app-contact-form>
