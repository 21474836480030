<section class="pb-5">
  <div class="container">
    <div class="row mb-5">
      <div class="col d-flex align-items-center">
        <div class="d-inline-block p-3 p-md-4 rounded-circle bg-primary-alt">
          <img
            src="assets/img/icons/theme/general/thunder-move.svg"
            alt="Confirmation Icon"
            class="icon bg-primary"
            data-inject-svg="data-inject-svg"
          />
        </div>
        <div class="ml-4">
          <h1 class="mb-2">Yeni Satış Kanalı</h1>
          <div class="lead">
            Hemen sitenizi açın ve satışa başlayın.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="bg-white">
  <div class="container">
    <div class="row">
      <div class="col">

      </div>
    </div>
  </div>
</div>
<div class="container pt-5">
  <form [formGroup]="newsiteForm" (ngSubmit)="onSiteCreate()">
    <div class="row justify-content-between align-items-start">
      <div class="col-lg-8">
        <div class="card card-body shadow mb-5">
          <h6 class="h2 text-center">Aşağıdaki bilgileri giriniz</h6>
          <div class="form-group">
            <input
              type="text"
              formControlName="siteName"
              class="form-control"
              name="siteName"
              placeholder="Sitenizin Adı"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              formControlName="siteDesc"
              class="form-control"
              name="siteDesc"
              placeholder="Sitenize bir açıklama yazmak isterseniz kullanabilirsiniz."
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              formControlName="siteUrl"
              class="form-control"
              name="siteUrl"
              placeholder="Sitenizin Alan Adı"
            />
            <small *ngIf="siteUrl?.value?.length>0">Sitenizin adresi <a href="#">https://{{ longUrl }}</a> olacak.</small>
          </div>
        </div>
      </div>
      <div class="col sticky-lg-top mb-5">
        <div class="pl-xl-4">
          <ul class="list-group">
            <li class="list-group-item">
              <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                  <input
                    type="checkbox"
                    formControlName="agree"
                    name="agree"
                    class="custom-control-input"
                    id="sign-up-agree"
                  />
                  <label class="custom-control-label" for="sign-up-agree"
                    >
                    <a target="_blank" href="utility-legal-terms.html"
                      >E-ticaret sitesi kullanma
                    </a> koşullarını okudum ve onaylıyorum.
                  </label>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div>
                <div>
                  <img class="icon bg-primary" src="assets/img/icons/theme/shopping/sale-2.svg" alt="Icon" data-inject-svg />
                  <h5 class="mt-4">Ücretsiz Deneyin!</h5>
                  <div>
                    Kudyu'yu 14 gün boyunca ücretsiz deneyin. Deneme süresinin sonunda ücretli üyeliğe geçin veya üyeliğinizi durdurun.
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <h6 class="mb-2">Yardım alın</h6>
              <a href="contact-1.html">Sorularınız için mesaj gönderin</a>
            </li>
          </ul>
          <button type="submit" [disabled]="!newsiteForm.valid" class="btn btn-outline-primary btn-block mt-3 mt-md-4">
            <div class="align-items-center">
              <img class="icon bg-primary" src="assets/img/icons/theme/code/done-circle.svg" alt="Icon" data-inject-svg />
              <span>Satışa Başla</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
