import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { strengthValidator, matchValidator } from 'src/app/validators/password.directive';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.sass']
})
export class SignupComponent implements OnInit {

  signupForm!: FormGroup;

  constructor(private api: ApiService, private router: Router, private auth: AuthService) {
  }

  ngOnInit(): void {

    const preEmail = localStorage.getItem('presignup-email');
    const preName = localStorage.getItem('presignup-name');


    this.signupForm = new FormGroup({
      customerName: new FormControl(preName,
        Validators.required
      ),
      email: new FormControl(preEmail, [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        strengthValidator(),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required
      ]),
      agree: new FormControl(false, [
        Validators.requiredTrue
      ]),
    });
    this.passwordConfirm?.setValidators(matchValidator(this.password!));

  }

  onSignup(): void {
    if (!this.agree?.value) {
      this.agree?.setErrors({ notAccepted: true });
    } else {
      const signUpData = {
        username: this.signupForm.value.email,
        name: this.signupForm.value.customerName,
        password: this.signupForm.value.password
      };

      this.api.postTypeRequest('/account/signup', signUpData).subscribe((res: any) => {
        console.log("register response", res.token);
        if (res.token) {
          this.auth.setDataInLocalStorage('token', res.token)
          //TODO: Later, refactor this to navigate account route and
          // update auth service to emit an event for subscribers to
          // take necessary actions e.g changing buttons and UI...
          this.router.navigate(['/hesabim'])
        }
      }, err => {
        console.log(err);
      });
    }
  }

  get password() {
    return this.signupForm.get('password');
  }

  get passwordConfirm() {
    return this.signupForm.get('confirmPassword');
  }

  get agree() {
    return this.signupForm.get('agree');
  }

}
