import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupComponent } from './screens/signup/signup.component';
import { HomeComponent } from './screens/home/home.component';
import { AccountComponent } from './screens/account/account.component';
import { SigninComponent } from './screens/signin/signin.component';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ActivateComponent } from './screens/activate/activate.component';
import { SignoutComponent } from './shared/components/signout/signout.component';
import { NewsiteComponent } from './screens/newsite/newsite.component';
import { PricingComponent } from './screens/pricing/pricing.component';
import {AboutComponent} from './screens/about/about.component';
import {FeaturesComponent} from './screens/features/features.component';
import {HelpComponent} from './screens/help/help.component';
import {ContactComponent} from './screens/contact/contact.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'hesabim', component: AccountComponent, canActivate: [AuthGuardService] },
  { path: 'ozellikler', component: FeaturesComponent },
  { path: 'hakkimizda', component: AboutComponent },
  { path: 'iletisim', component: ContactComponent },
  { path: 'uye-ol', component: SignupComponent },
  { path: 'giris', component: SigninComponent },
  { path: 'fiyatlandirma', component: PricingComponent },
  { path: 'yardim', component: HelpComponent },
  { path: 'activate/:id', component: ActivateComponent },
  { path: 'site', component: NewsiteComponent, canActivate: [AuthGuardService] },
  { path: 'cikis', component: SignoutComponent },
  { path: '**', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling : 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
