import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { chainedInstruction } from '@angular/compiler/src/render3/view/util';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-newsite',
  templateUrl: './newsite.component.html',
  styleUrls: ['./newsite.component.sass']
})
export class NewsiteComponent implements OnInit {


  newsiteForm!: FormGroup;

  validChars: string = "abcdefghijklmnopqrstuvwxyz0123456789-";

  urlFormat: string = "@.kudyu.com";
  longUrl: string = "";
  maxLen: number = 60;

  currentSiteName = "";
  currentSiteUrl = "";

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit(): void {

    this.newsiteForm = new FormGroup({
      siteName: new FormControl('',
        Validators.required
      ),
      siteUrl: new FormControl('', [
        Validators.required
      ]),
      agree: new FormControl(false, [
        Validators.requiredTrue
      ]),
    });

    this.newsiteForm.controls['siteName'].valueChanges.subscribe(change => {
      this.buildDomain(change);
    });
  }

  buildDomain(name: string): void {
    name = name.toLowerCase().replace(/[^a-z0-9]/g, '');
    if (name.length <= this.maxLen) {
      this.newsiteForm.controls['siteUrl'].setValue(name);
      this.longUrl = this.urlFormat.replace(/\@/g, name);
      this.currentSiteUrl = name;
    }
  }

  onSiteCreate(): void {
    this.newsiteForm.value.siteUrl = this.currentSiteUrl;
    this.api.postTypeRequest('/site/create', {
      sitename: this.newsiteForm.value.siteName,
      siteurl: this.longUrl,
    }).subscribe((r: any) => {
      if (r.success){
        localStorage.setItem("newSiteAdded", this.newsiteForm.value.siteName);
        this.router.navigate(['/hesabim']);
      }else{
        alert("hata");
      }
    })

  }

  get siteName() {
    return this.newsiteForm.get('siteName');
  }

  get siteUrl() {
    return this.newsiteForm.get('siteUrl');
  }


}
