<app-slider></app-slider>

<!--PROPERTIES-->
<section id="about-content" class="o-hidden props">
    <div class="container">
        <div class="row align-items-center justify-content-around text-center text-lg-left">
            <div class="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
                <div>
                    <h3 class="h1">Hakkımızda</h3>
                    <p class="lead">
                        Kudyu, Türkiye'deki işletmelerin yıllık üyelik taahhütleri olmadan, uygun maliyetlerle e-ticaret ve dijital satış kanallarına sahip olmalarını sağlamak amacıyla 2021 yılında kuruldu.
                    </p>
                    <p class="lead">

                    </p>
                </div>
            </div>
            <div class="col-md-9 col-lg-6 col-xl-5">
                <img src="assets/img/about-us-1.gif" alt="Image" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<section class="o-hidden pt-0 props">
    <div class="container">
        <div class="row align-items-center justify-content-around text-center text-lg-left">
            <div class="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 order-lg-2 pl-lg-5 pl-xl-0">
                <div>
                    <h3 class="h1">Esnek, Güçlü Platform</h3>
                    <p class="lead">
                        Paket e-ticaret yazılımları esnek değildir. Geliştirilmeleri ve ihtiyaçlarınıza göre uyarlanmaları ya imkansızdır ya da çok zaman alır. Hazır açık kaynak yazılımları ise kurmak, yönetmek için yazılım ekibine ihtiyaç duyarsınız. Kudyu ise bu iki yolun
                        dışında size hem en esnek hem de en güçlü platformu sunmak için oluşturuldu.<br><br> Siz işinize odaklanırken sisteminiz gelişecek ve her gün güçlenecek.
                    </p>
                </div>
            </div>
            <div class="col-md-9 col-lg-6 col-xl-5 order-lg-1">
                <img src="assets/img/about-us-2@2x.jpg" alt="Image" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<app-support-box></app-support-box>