<app-slider></app-slider>

<section id="help-content" class="p-0 faq">
    <div class="divider divider-top"></div>
    <div class="container">
        <div class="row section-title justify-content-center text-center">
            <div class="col-md-9 col-lg-8 col-xl-8">
                <h3 class="display-4">Sık Sorulan Sorular</h3>
                <div class="lead">
                    Aklınıza takılan ne varsa yanıtlamaktan memnuniyet duyarız. Kudyu'da müşterilerimize destek vermek ve onların sorunlarını çözmek bizim birinci önceliğimizdir. Bu sayfada bulamadığınız sorular varsa bizimle iletişme geçmenizi rica ederiz.
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div id="faq-accordion">
                    <div class="card mb-2 mb-md-3">
                        <a href="#accordion-1" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="mb-0 mr-2">Ürün, Kategori veya Kullanıcı Trafiği sınırı var mıdır?</h6>
                                <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm">
                            </div>
                        </a>
                        <div class="collapse" id="accordion-1" data-parent="#faq-accordion">
                            <div class="px-3 px-md-4 pb-3 pb-md-4">
                                Kudyu platformunda ürün, kategori veya site trafiği konularında herhangi bir sınır bulunmuyor.
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2 mb-md-3">
                        <a href="#accordion-2" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="mb-0 mr-2">Ödeme komisyonları alıyor musunuz?</h6>
                                <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm">
                            </div>
                        </a>
                        <div class="collapse" id="accordion-2" data-parent="#faq-accordion">
                            <div class="px-3 px-md-4 pb-3 pb-md-4">
                                Kudyu platformunda aldığınız siparişler ve ödemelerden herhangi bir komisyon alınmıyor. Kudyu bu yönüyle bütün rakiplerinden ayrılır.

                            </div>
                        </div>
                    </div>
                    <div class="card mb-2 mb-md-3">
                        <a href="#accordion-3" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="mb-0 mr-2">Hangi ödeme yöntemlerini kullanabilirim ?</h6>
                                <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm">
                            </div>
                        </a>
                        <div class="collapse" id="accordion-3" data-parent="#faq-accordion">
                            <div class="px-3 px-md-4 pb-3 pb-md-4">
                                Kudyu platformuna sürekli yeni ödeme yöntemleri eklenmektedir. Şu anda İyzico, Pay TR, Stripe ve PayPal ödeme yöntemleri sağlanmaktadır.
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2 mb-md-3">
                        <a href="#accordion-4" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="mb-0 mr-2">Kudyu API ile entegre olabilir miyim?</h6>
                                <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm">
                            </div>
                        </a>
                        <div class="collapse" id="accordion-4" data-parent="#faq-accordion">
                            <div class="px-3 px-md-4 pb-3 pb-md-4">
                                Kudyu API, e-ticaret sisteminizin dışarı açılan kapısıdır. Bu API sisteminizle birlikte kurulu gelecektir. Hemen kullanmaya başlayabilirsiniz. Entegratör firmalarla paylaşabilir ve dışarıdan veri almaya veya vermeye hemen başlayabilirsiniz.
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2 mb-md-3">
                        <a href="#accordion-5" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="mb-0 mr-2">Sitem için özgün ve yeni bir tasarıma sahip olabilir miyim?</h6>
                                <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm">
                            </div>
                        </a>
                        <div class="collapse" id="accordion-5" data-parent="#faq-accordion">
                            <div class="px-3 px-md-4 pb-3 pb-md-4">
                                Tabi ki. Sizinle yapacağımız görüşmede ihtiyaçlarınızı anlayıp sitenize özel premium bir tema geliştirebiliriz. Yeter ki siz isteklerinizi anlatın ve büyük hedefler koyun. Dilerseniz birbirinden güzel temalarımızdan birini seçerek hemen kullanmaya da
                                başlayabilrsiniz.
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2 mb-md-3">
                        <a href="#accordion-6" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="mb-0 mr-2">Demo görebilir miyim?</h6>
                                <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm">
                            </div>
                        </a>
                        <div class="collapse" id="accordion-6" data-parent="#faq-accordion">
                            <div class="px-3 px-md-4 pb-3 pb-md-4">
                                Demo için lütfen bizimle iletişime geçiniz.
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2 mb-md-3">
                        <a href="#accordion-7" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="mb-0 mr-2">Üyeliğimi nasıl başlatabilirim?</h6>
                                <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm">
                            </div>
                        </a>
                        <div class="collapse" id="accordion-7" data-parent="#faq-accordion">
                            <div class="px-3 px-md-4 pb-3 pb-md-4">
                                Şu anda Kudyu platformu ile e-ticarete başlamak için bizimle iletişime geçmeniz gerekiyor. Yakın zamanda ise self servis modelini de seçeneklerimize ekleyeceğiz. O zamana kadar bizimle iletişime geçmek için tıklayın.
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2 mb-md-3">
                        <a href="#accordion-8" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="mb-0 mr-2">Üyelik ücreti nedir?</h6>
                                <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm">
                            </div>
                        </a>
                        <div class="collapse" id="accordion-8" data-parent="#faq-accordion">
                            <div class="px-3 px-md-4 pb-3 pb-md-4">
                                Üyelik fiyatı ihtiyaçlarınıza göre belirlenir ve aylık olarak tahsil edilir.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider divider-bottom"></div>
</section>

<app-support-box></app-support-box>
