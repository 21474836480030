<div class="navbar-container">
    <nav class="navbar navbar-expand-lg navbar-dark" data-overlay data-sticky="top">
        <div class="container">
            <a class="navbar-brand navbar-brand-dynamic-color fade-page" href="/">
                <img src="/assets/img/logos/logo-white.svg" data-inject-svg width="100" alt="Kudyu">
            </a>
            <div class="d-flex align-items-center order-lg-3">
                        <a
                          *ngIf="!isLogged && false"
                          routerLink="/uye-ol"
                          class="btn btn-primary ml-lg-4 mr-3 mr-md-4 mr-lg-0 d-none d-sm-block order-lg-3 btn-kudyu-thin"
                         >ÜYE OL</a>

                <a routerLink="/iletisim" fragment="form-section" class="btn btn-primary ml-lg-4 mr-3 mr-md-4 mr-lg-0 d-none d-sm-block order-lg-3 btn-kudyu-thin">İLETİŞİM</a>

                <div class="dropdown" *ngIf="isLogged">
                    <button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-outline-primary dropdown-toggle arrow-bottom" data-toggle="dropdown" type="button">
            <img
              class="icon bg-primary"
              src="assets/img/icons/theme/general/thunder-move.svg"
              alt="Hesap"
              data-inject-svg
            />
            <span>HESABIM</span>
          </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" routerLink="/hesabim">HESABI YÖNET</a>
                        <a class="dropdown-item" routerLink="/cikis">GÜVENLİ ÇIKIŞ</a>
                    </div>
                </div>

                <button aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler" data-target=".navbar-collapse" data-toggle="collapse" type="button">
          <img
            alt="Navbar Toggler Open Icon"
            class="navbar-toggler-open icon icon-sm"
            data-inject-svg
            src="assets/img/icons/interface/icon-menu.svg"
          />
          <img
            alt="Navbar Toggler Close Icon"
            class="navbar-toggler-close icon icon-sm"
            data-inject-svg
            src="assets/img/icons/interface/icon-x.svg"
          />
        </button>
            </div>
            <div class="collapse navbar-collapse order-3 order-lg-2 justify-content-lg-end" id="navigation-menu">
                <ul class="navbar-nav my-3 my-lg-0">
                    <li class="nav-item">
                        <a class="nav-link nav-item" routerLink="/hakkimizda" fragment="about-content" role="button">HAKKIMIZDA
            </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link nav-item" routerLink="/ozellikler" fragment="feature-content" role="button">ÖZELLİKLER</a>
                    </li>
                    <li class="nav-item">
                        <div class="dropdown">
                            <a class="nav-link nav-item" routerLink="/yardim" fragment="help-content">YARDIM</a>
                        </div>
                    </li>
                </ul>
<!--                <div class="d-flex d-md-none justify-content-center">-->
<!--                  <a href="" class="btn btn-link mobil-btn-start">HEMEN BAŞLAYIN</a>-->
<!--                </div>-->
                <div class="d-flex justify-content-center d-md-none">
                  <a [routerLink]="['/iletisim']" fragment="form-section" class="btn btn-primary w-75 mobil-btn-contact">
                    İLETİŞİM
                  </a>
                </div>
            </div>
        </div>
    </nav>
</div>
