import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";

declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  preSignupForm!: FormGroup;

  constructor(private router: Router) { }

  ngOnInit(): void {

    // tslint:disable-next-line:typedef only-arrow-functions
    jQuery(document).on('shown.bs.modal layoutComplete', function(t: any) {
      // tslint:disable-next-line:only-arrow-functions typedef
      jQuery(t.target).find('[data-flickity]').each(function(t: any, e: any) {
        const n = jQuery(e);
        // tslint:disable-next-line:no-unused-expression
        n.data().flickity.isInitActivated && n.flickity('resize');
      });
    });
    this.preSignupForm = new FormGroup({
      name: new FormControl('',
        Validators.required
      ),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ])
    });
  }

  onPreSignUp(): void {
    localStorage.setItem('presignup-name', this.preSignupForm.value['name']);
    localStorage.setItem('presignup-email', this.preSignupForm.value['email']);
    this.router.navigate(['/uye-ol'])
  }

}
