import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './screens/signup/signup.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HomeComponent } from './screens/home/home.component';
import { AccountComponent } from './screens/account/account.component';
import { SigninComponent } from './screens/signin/signin.component';

import { ReactiveFormsModule } from '@angular/forms';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';
import { ActivateComponent } from './screens/activate/activate.component';
import { SignoutComponent } from './shared/components/signout/signout.component';
import { VerifyaccComponent } from './shared/components/verifyacc/verifyacc.component';
import { NewsiteComponent } from './screens/newsite/newsite.component';
import { PricingComponent } from './screens/pricing/pricing.component';
import { AboutComponent } from './screens/about/about.component';
import { SliderComponent } from './shared/components/slider/slider.component';
import { SupportBoxComponent } from './shared/components/support-box/support-box.component';
import { FeaturesComponent } from './screens/features/features.component';
import { CtaComponent } from './shared/components/cta/cta.component';
import { HelpComponent } from './screens/help/help.component';
import { ContactFormComponent } from './shared/components/contact-form/contact-form.component';
import { ContactComponent } from './screens/contact/contact.component';


@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AccountComponent,
    SigninComponent,
    PagenotfoundComponent,
    ActivateComponent,
    SignoutComponent,
    VerifyaccComponent,
    NewsiteComponent,
    PricingComponent,
    AboutComponent,
    SliderComponent,
    SupportBoxComponent,
    FeaturesComponent,
    CtaComponent,
    HelpComponent,
    ContactFormComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
