import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SpinnerService } from './spinner.service';

declare var $: any;


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private REST_API_SERVER = environment.apiUrl;

  constructor(private httpClient: HttpClient, private spinnerService: SpinnerService) { }

  initilizeHeader(){
    const headers = new HttpHeaders({
        'Content-Type':`application/json`,
        'Accept':`*/*`,
        'Access-Control-Allow-Origin': `*`
    });
    return headers;
  };


  getTypeRequest(url: string) {
    this.spinnerService.showSpinner();
    return this.httpClient.get(this.REST_API_SERVER + url, {headers: this.initilizeHeader()}).pipe(map(res => {
      this.spinnerService.hideSpinner();
      return res;
    }));
  }

  postTypeRequest(url: string, payload: any) {
    this.spinnerService.showSpinner();
    return this.httpClient.post(this.REST_API_SERVER + url, payload, {headers: this.initilizeHeader()}).pipe(map(res => {
      this.spinnerService.hideSpinner();
      return res;
    }));
  }

  putTypeRequest(url: string, payload: any) {
    this.spinnerService.showSpinner();
    return this.httpClient.put(this.REST_API_SERVER + url, payload, {headers: this.initilizeHeader()}).pipe(map(res => {
      this.spinnerService.hideSpinner();
      return res;
    }))
  }
}
