
    <div class="bg-primary-3 text-white o-hidden" data-overlay>
        <section class="pb-0">
          <div class="container pb-5">
            <div class="row text-center justify-content-center">
              <div class="col">
                <h1 class="display-3">Kudyu'yu <mark>deneyin</mark>, kararınızı sonra verin.</h1>
              </div>
            </div>
            <div class="row text-center justify-content-center">
              <div class="col-md-9 col-lg-8 col-xl-7">
                <p class="lead">14 Gün Ücretsiz Deneyin. Üstelik kredi kartı bilgilerinizi vermeden. Sonra devam etmek isterseniz bir plan satın alın.</p>
              </div>
            </div>
          </div>
          <div class="position-absolute w-100 h-100 bottom right" data-jarallax-element="100">
            <div class="blob blob-4 bg-gradient top left w-100 h-100"></div>
          </div>
          <div class="divider divider-bottom"></div>
        </section>
      </div>
      <section class="pt-5">
        <div class="container">
          <div class="row justify-content-end sticky-top bg-white">
            <div class="col-lg-3 col-4 py-3 py-md-4 border-bottom">
              <div class="d-flex flex-column align-items-center text-center">
                <h4>Kudyu</h4>
                <div class="d-flex align-items-center justify-content-center mb-1">
                    <span class="display-4 mb-0 text-dark">99</span>
                    <span class="h3 mb-0 mr-1 mr-sm-2">₺</span>
                  </div>
                <div class="text-small mb-3 mb-md-4">Aylık ödenir</div>
                <a href="#" class="btn btn-danger btn-block">
                    <span class="d-none d-md-inline-block">Üyeliğimi Başlat</span>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-4 py-3 py-md-4 border-bottom">
              <div class="d-flex flex-column align-items-center text-center">
                <h4>Kudyu Pro</h4>
                <div class="d-flex align-items-center justify-content-center mb-1">
                  <span class="display-4 mb-0 text-dark">169</span>
                  <span class="h3 mb-0 mr-1 mr-sm-2">₺</span>
                </div>
                <div class="text-small mb-3 mb-md-4">Aylık ödenir</div>
                <a href="#" class="btn btn-danger btn-block">
                  <span class="d-none d-md-inline-block">Üyeliğimi Başlat</span>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-4 py-3 py-md-4 border-bottom">
              <div class="d-flex flex-column align-items-center text-center">
                <h4>Özel</h4>
        
                <div class="text-small mb-3 mb-md-4">
                    İşletmenize özel tasarım ve özelleştirilmiş yazılım için bizimle temasa geçin. Sizin için özel çözümü birlikte oluşturalım.

                </div>
                <a href="#" class="btn btn-outline-info btn-block">
                  <span>İletişime Geç</span>                  
                </a>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <div class="pricing-table-section text-center text-lg-left">
              <div class="row no-gutters">
                <div class="col">
                  <h5 class="mb-4">Temel Özellikler</h5>
                </div>
              </div>
              <div class="border rounded">
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">ETicaret Sitesi</h6>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>1</span>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>3</span>
                  </div>
                  <!--<div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>Unlimited</span>
                  </div>-->
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Client Billing</h6>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
                 
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Restricted Access</h6>
                      <div class="ml-2 tooltip-circle bg-dark text-white" data-toggle="tooltip" title="Here's a tooltip to help explain this feature">?</div>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-danger-alt">
                      <img src="assets/img/icons/interface/icon-x.svg" alt="Included" class="m-2 icon icon-xs bg-danger" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>Enhanced</span>
  
                  </div>
              
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Code Export</h6>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-danger-alt">
                      <img src="assets/img/icons/interface/icon-x.svg" alt="Included" class="m-2 icon icon-xs bg-danger" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
             
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Project Transfers</h6>
                      <div class="ml-2 tooltip-circle bg-dark text-white" data-toggle="tooltip" title="Here's a tooltip to help explain this feature">?</div>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-danger-alt">
                      <img src="assets/img/icons/interface/icon-x.svg" alt="Included" class="m-2 icon icon-xs bg-danger" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>Unlimited</span>
  
                  </div>
        
                </div>
              </div>
            </div>
            <div class="pricing-table-section text-center text-lg-left">
              <div class="row no-gutters">
                <div class="col">
                  <h5 class="mb-4">Hosting Features</h5>
                </div>
              </div>
              <div class="border rounded">
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Hosting Domains</h6>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>1</span>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>100</span>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>Unlimited</span>
  
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Domain Aliases</h6>
                      <div class="ml-2 tooltip-circle bg-dark text-white" data-toggle="tooltip" title="Here's a tooltip to help explain this feature">?</div>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-danger-alt">
                      <img src="assets/img/icons/interface/icon-x.svg" alt="Included" class="m-2 icon icon-xs bg-danger" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Bandwidth Allowance</h6>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>20GB</span>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>Unlimited</span>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>Unlimited</span>
  
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Analytics</h6>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-danger-alt">
                      <img src="assets/img/icons/interface/icon-x.svg" alt="Included" class="m-2 icon icon-xs bg-danger" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-danger-alt">
                      <img src="assets/img/icons/interface/icon-x.svg" alt="Included" class="m-2 icon icon-xs bg-danger" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
                </div>
              </div>
            </div>
            <div class="pricing-table-section text-center text-lg-left">
              <div class="row no-gutters">
                <div class="col">
                  <h5 class="mb-4">E-Commerce Features</h5>
                </div>
              </div>
              <div class="border rounded">
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Products</h6>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>15</span>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>Unlimited</span>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>Unlimited</span>
  
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Custom Checkout</h6>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Transaction Fee</h6>
                      <div class="ml-2 tooltip-circle bg-dark text-white" data-toggle="tooltip" title="Here's a tooltip to help explain this feature">?</div>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>5.10%</span>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>3.30%</span>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>1.25%</span>
  
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">Analytics</h6>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-danger-alt">
                      <img src="assets/img/icons/interface/icon-x.svg" alt="Included" class="m-2 icon icon-xs bg-danger" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-success-alt">
                      <img src="assets/img/icons/interface/icon-check.svg" alt="Included" class="m-2 icon icon-xs bg-success" data-inject-svg>
                    </div>
  
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-12 col-lg-3  py-3 py-md-4">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                      <h6 class="mb-0 ml-lg-4">API Support</h6>
                    </div>
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
  
                    <div class="rounded-circle bg-danger-alt">
                      <img src="assets/img/icons/interface/icon-x.svg" alt="Included" class="m-2 icon icon-xs bg-danger" data-inject-svg>
                    </div>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>3 Connections</span>
  
                  </div>
                  <div class="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                    <span>Unlimited Connections</span>
  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="p-0">
        <div class="container">
          <div class="row">
            <div class="col-md-4 mb-4 mb-md-0 text-center">
              <div class="px-xl-3">
                <div>
                  <div class="d-inline-block mb-4 p-3 p-md-4 rounded-circle bg-primary-2-alt">
                    <img src="assets/img/icons/theme/navigation/route.svg" alt="Binoculars icon" class="icon bg-primary-2" data-inject-svg>
                  </div>
                  <h5>Fully Responsive</h5>
                  <div>
                    Ned ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque Arya.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-4 mb-md-0 text-center">
              <div class="px-xl-3">
                <div>
                  <div class="d-inline-block mb-4 p-3 p-md-4 rounded-circle bg-primary-2-alt">
                    <img src="assets/img/icons/theme/layout/layout-left-panel-2.svg" alt="Layouts icon" class="icon bg-primary-2" data-inject-svg>
                  </div>
                  <h5>Multiple Layouts</h5>
                  <div>
                    Non pulvinar neque laoreet suspendisse interdum Catelyn libero id. Olenna imp leo in vitae turpis massa. Sapien habitant Tyrion.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-4 mb-md-0 text-center">
              <div class="px-xl-3">
                <div>
                  <div class="d-inline-block mb-4 p-3 p-md-4 rounded-circle bg-primary-2-alt">
                    <img src="assets/img/icons/theme/shopping/box-2.svg" alt="Box icon" class="icon bg-primary-2" data-inject-svg>
                  </div>
                  <h5>Modular Components</h5>
                  <div>
                    Khaleesi vedit sodales ut eu. Melisandre pellentesque elit eget Gendry est sociis natoque penatibus. Eunuch pulvinar sapien et Loras.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="divider divider-bottom"></div>
      </section>
      <section class="bg-light">
        <div class="container">
          <div class="row section-title justify-content-center text-center">
            <div class="col-md-9 col-lg-8 col-xl-7">
              <h3 class="display-4">FAQ</h3>
              <div class="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-9 col-lg-8 col-xl-7">
              <div id="faq-accordion">
                <div class="card mb-2 mb-md-3">
                  <a href="#accordion-1" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                    <div class="d-flex justify-content-between align-items-center">
                      <h6 class="mb-0 mr-2">Can I upgrade later on?</h6>
                      <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm" data-inject-svg>
                    </div>
                  </a>
                  <div class="collapse" id="accordion-1" data-parent="#faq-accordion">
                    <div class="px-3 px-md-4 pb-3 pb-md-4">
                      Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre. Vivamus ornare pharetra diam sit amet tincidunt. Eunuch sit amet pharetra odio. Vivamus in tempor ipsum, sit amet elementum neque. Sed faucibus posuere pharetra.
                      In imperdiet eleifend dui a aliquet. Aliquam imperdiet Tyrion purus vitae rutrum. Donec eu commodo nunc. Mauris dignissim lectus massa, eget cursus quam mollis id. Eddard sit amet ex Jon nibh maximus cursus at vitae mi. Duis tincidunt
                      aliquam mi sed sagittis.
  
                    </div>
                  </div>
                </div>
                <div class="card mb-2 mb-md-3">
                  <a href="#accordion-2" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                    <div class="d-flex justify-content-between align-items-center">
                      <h6 class="mb-0 mr-2">Can I port my data from another provider?</h6>
                      <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm" data-inject-svg>
                    </div>
                  </a>
                  <div class="collapse" id="accordion-2" data-parent="#faq-accordion">
                    <div class="px-3 px-md-4 pb-3 pb-md-4">
                      Eunuch nec dapibus ex. Aenean placerat, ex imp convallis dictum, ex nulla rutrum justo, Jon lobortis nisi ex at leo. Sed Tyrion aliquet sem vel pharetra. Vestibulum ante ipsum primis in faucibus Hodor luctus et ultrices posuere cubilia Curae; Class aptent
                      taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis a sapien quis quam auctor feugiat. Donec volutpat condimentum risus, eu iaculis nibh dapibus eu.
  
                    </div>
                  </div>
                </div>
                <div class="card mb-2 mb-md-3">
                  <a href="#accordion-3" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                    <div class="d-flex justify-content-between align-items-center">
                      <h6 class="mb-0 mr-2">Are my food photos stored forever in the cloud?</h6>
                      <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm" data-inject-svg>
                    </div>
                  </a>
                  <div class="collapse" id="accordion-3" data-parent="#faq-accordion">
                    <div class="px-3 px-md-4 pb-3 pb-md-4">
                      Volantisi fringilla, unuch eu sagittis sagittis, urna Loras luctus odio, vitae hendrerit massa dui ac est. Donec leo tortor, Tyrion et aliquet at, convallis imp mi. Vivamus turpis diam, ultrices et tempus quis, sollicitudin et risus. Pellentesque nec
                      sapien imp dolor condimentum condimentum ut sed neque. Integer efficitur accumsan risus, vitae posuere massa aliquam at.
  
                    </div>
                  </div>
                </div>
                <div class="card mb-2 mb-md-3">
                  <a href="#accordion-4" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                    <div class="d-flex justify-content-between align-items-center">
                      <h6 class="mb-0 mr-2">Who foots the bill for that?</h6>
                      <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm" data-inject-svg>
                    </div>
                  </a>
                  <div class="collapse" id="accordion-4" data-parent="#faq-accordion">
                    <div class="px-3 px-md-4 pb-3 pb-md-4">
                      Khaleesi ornare pharetra diam sit amet tincidunt. Eunuch sit amet pharetra odio. Vivamus in tempor ipsum, sit amet elementum neque. Sed faucibus posuere pharetra. In imperdiet eleifend dui a aliquet. Aliquam imperdiet Tyrion purus vitae rutrum. Donec
                      eu commodo nunc. Vivamus Melisandre Jon lorem eget bibendum. Sed tincidunt sed enim at dignissim. Mauris erat diam, lacinia eget efficitur et, iaculis sed augue.
  
                    </div>
                  </div>
                </div>
                <div class="card mb-2 mb-md-3">
                  <a href="#accordion-5" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                    <div class="d-flex justify-content-between align-items-center">
                      <h6 class="mb-0 mr-2">What's the real cost?</h6>
                      <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm" data-inject-svg>
                    </div>
                  </a>
                  <div class="collapse" id="accordion-5" data-parent="#faq-accordion">
                    <div class="px-3 px-md-4 pb-3 pb-md-4">
                      Brienne ac maximus Loras, eu placerat odio. Etiam vestibulum Loras et sollicitudin pellentesque. Mauris sed Tyrion Varys. Curabitur posuere augue risus, eget mollis unuch consectetur quis. Vestibulum accumsan congue risus, in semper eros interdum id.
                      Tincidunt vitae libero efficitur viverra. Integer venenatis massa in dui viverra fermentum. Eunuch fringilla arcu ac urna sodales fermentum. Ut luctus enim ut sagittis consectetur.
  
                    </div>
                  </div>
                </div>
                <div class="card mb-2 mb-md-3">
                  <a href="#accordion-6" data-toggle="collapse" role="button" aria-expanded="false" class="p-3 p-md-4">
                    <div class="d-flex justify-content-between align-items-center">
                      <h6 class="mb-0 mr-2">Can my company request a custom plan?</h6>
                      <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" class="icon icon-sm" data-inject-svg>
                    </div>
                  </a>
                  <div class="collapse" id="accordion-6" data-parent="#faq-accordion">
                    <div class="px-3 px-md-4 pb-3 pb-md-4">
                      Brienne ac maximus Loras, eu placerat odio. Etiam vestibulum Loras et sollicitudin pellentesque. Mauris sed Tyrion Varys. Curabitur posuere augue risus, eget mollis unuch consectetur quis. Vestibulum accumsan congue risus, in semper eros interdum id.
  
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
          <div class="row justify-content-center mt-4 mt-md-5">
            <div class="col-auto">
              <div class="alert bg-secondary">Still have unanswered questions? <a href="#">Get in touch</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-primary text-white pb-0">
        <div class="container">
          <div class="row section-title justify-content-center text-center">
            <div class="col-md-9 col-lg-8 col-xl-7">
              <h3 class="display-4">People are diggin’ it</h3>
              <div class="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-9 col-md-10 mb-3 mb-sm-4" data-aos="fade-up" data-aos-delay="100">
              <div class="card card-body flex-md-row pl-md-3 bg-white">
                <img src="assets/img/avatars/male-4.jpg" alt="Harvey Derwent avatar image" class="avatar d-block mr-md-5 ml-md-n5 mb-3 mb-md-0">
                <div>
                  <blockquote class="blockquote pl-0 border-0 mb-3 mb-sm-4">
              &ldquo;We had all sorts of problems around motivation and productivity from our smallest scrums to our largest teams.  Jumpstart helped us rise above all and conquer.&rdquo;
          </blockquote>
                  <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                    <h6 class="mb-0 mr-2">Harvey Derwent</h6>
                    <span>Resident Zen Master</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-9 col-md-10 mb-3 mb-sm-4" data-aos="fade-up" data-aos-delay="200">
              <div class="card card-body flex-md-row pl-md-3 bg-white">
                <img src="assets/img/avatars/male-1.jpg" alt="Harvey Derwent avatar image" class="avatar d-block mr-md-5 ml-md-n5 mb-3 mb-md-0">
                <div>
                  <blockquote class="blockquote pl-0 border-0 mb-3 mb-sm-4">
              &ldquo;We had all sorts of problems around motivation and productivity from our smallest scrums to our largest teams. <mark data-aos="highlight-text" data-aos-delay="200">Jumpstart helped us rise above all and conquer.</mark>&rdquo;
          </blockquote>
                  <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                    <h6 class="mb-0 mr-2">Harvey Derwent</h6>
                    <span>Resident Zen Master</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-9 col-md-10 mb-3 mb-sm-4" data-aos="fade-up" data-aos-delay="300">
              <div class="card card-body flex-md-row pl-md-3 bg-white">
                <img src="assets/img/avatars/female-4.jpg" alt="Shelley McNabb avatar image" class="avatar d-block mr-md-5 ml-md-n5 mb-3 mb-md-0">
                <div>
                  <blockquote class="blockquote pl-0 border-0 mb-3 mb-sm-4">
              &ldquo;We all know the stigma around build times and the ever expanding arsenal of tooling in modern web apps. Fear not, Jumpstart does away with all of that.&rdquo;
          </blockquote>
                  <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                    <h6 class="mb-0 mr-2">Shelley McNabb</h6>
                    <span>Software Engineer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="divider divider-bottom bg-primary-3"></div>
      </section>