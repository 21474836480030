<div
      class="alert alert-info bg-secondary alert-dismissible fade show mb-5"
      role="alert"
      *ngIf="!verified"
    >
      <p>
        <img
          class="icon bg-danger"
          src="assets/img/icons/theme/communication/shield-user.svg"
          alt="Icon"
          data-inject-svg
        />
        Epostanızı henüz onaylamadınız. Site oluşturmak için lütfen epostanızı
        kontrol edin.
        <button class="btn btn-sm btn-primary" [disabled]="!sendAgainEnabled" (click)="onSendConf()">Tekrar gönder</button>
      </p>
    </div>