
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

declare var hbspt: any;


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.sass']
})


export class ContactFormComponent implements OnInit {
  

  contactForm!: FormGroup;

  messageSent: boolean = false;
  messageSending: boolean = false;

  buttonText = 'İLETİŞİME GEÇİN';

  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      getstartedname: new FormControl('',
        Validators.required
      ),
      getstartedemail: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      getstartedphone: new FormControl('',
        Validators.required
      ),
      getstartedcompany: new FormControl('',
        Validators.required
      ),
      getstartednotes: new FormControl('',
        Validators.required
      )
    });
    this.contactForm.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.buttonText = 'İLETİŞİME GEÇİN';
      }
    });
  }

  ngAfterViewInit(): void {
    hbspt.forms.create({
      region: "eu1",
      portalId: "144457385",
      formId: "7fb60c5a-861c-45eb-a38c-d4d7c4208537",
      target: '#hubspotForm',
      css: ''
    });
  }

  resetForm(): void {
    this.messageSent = false;
    this.messageSending = false;

    this.router.navigate(['/'], { fragment: 'top-anchor' });
  }

  onSend(): void {

    this.messageSending = true;

    if (!this.contactForm.valid) {
      this.buttonText = 'LÜTFEN FORMU DOLDURUN';
      return;
    }

    const msg = {
      name: this.contactForm.value.getstartedname,
      email: this.contactForm.value.getstartedemail,
      phone: this.contactForm.value.getstartedphone,
      company: this.contactForm.value.getstartedcompany,
      message: this.contactForm.value.getstartednotes
    };

    this.apiService.postTypeRequest('/contact', msg).subscribe((res: any) => {
      this.messageSending = false;
      if (res.success) {
        this.messageSent = true;
      }
    }, err => {
      this.messageSending = false;
      console.log(err);
    });
  }

}
