
    <div data-overlay class="min-vh-100 bg-light d-flex flex-column justify-content-md-center">
        <section class="py-3">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-4 col-lg-5 col-md-6">
                <div class="card card-body shadow">
                  <h1 class="h5 text-center">Giriş Yap</h1>
                  <div *ngIf="loginFailed" class="alert alert-warning" role="alert">
                    Email ya da şifre hatalı. Lütfen tekrar deneyin.
                  </div>
                  <form [formGroup]="signinForm" (ngSubmit)="onSignin()">
                    <div class="form-group">
                      <input formControlName="email" type="email" class="form-control" name="email" placeholder="Eposta adresi">
                    </div>
                    <div class="form-group">
                      <input formControlName="password" type="password" class="form-control" name="password" placeholder="Şifre">
                      <div class="text-right text-small mt-2">
                        <a href="#">Şifreni mi unuttun?</a>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="custom-control custom-checkbox text-small">
                        <input type="checkbox" formControlName="rememberMe" class="custom-control-input" id="sign-in-remember">
                        <label class="custom-control-label" for="sign-in-remember">Beni hatırla</label>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-block" [disabled]="!signinForm.valid || inProgress" type="submit">Giriş</button>
                  </form>
                </div>
                <div class="text-center text-small mt-3">
                  Hesabınız yok mu? <a routerLink="/uye-ol">Yeni hesap açmak için tıklayın.</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>