import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.sass']
})
export class ActivateComponent implements OnInit {

  id: string = "";
  private sub: any;
  activateSuccess: boolean = false;
  loggedIn : boolean = false;

  constructor(private route: ActivatedRoute, private api: ApiService, private auth: AuthService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.loggedIn = this.auth.isLoggedIn();

      if (this.id != "") {
        this.api.postTypeRequest('/account/activate', { code: this.id }).subscribe((res: any) => {
          if (res.success && res.activated) {
            this.activateSuccess = true;
          }
        })
      }

    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
