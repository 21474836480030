<app-slider></app-slider>

<!--SLIDER BOTTOM-->
<section id="feature-content" class="bg-white o-hidden slider-bottom pb-2">
    <div class="container">
        <div class="row section-title justify-content-center text-center">
            <div class="col-md-9 col-lg-9 col-xl-9">
                <h2 class="display-4">Özellikler</h2>
                <div class="lead">
                    Kudyu E-ticaret Platformu sürekli güncellenmesi özelliği ile standart paket sistemlerden ayrılır. Aşağıda genel hatları bulunan özellikler ve çok daha fazlasını içeren tamamen size ait, esnek bir eticaret altyapısına en uygun maliyetlerle sahip olacaksınız.
                    Kudyu, hepsinden farklı!
                </div>
            </div>
        </div>
    </div>
</section>

<section class="o-hidden pt-0 features-list">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Mobil Öncelikli Kullanıcı Deneyimi</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Hazır Dil Paketleri ile Dünya'ya Açılın</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Premium Tasarımlar ve Kusursuz Müşteri Deneyimi</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Güçlü SEO Özellikleri</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">B2B ve B2C ile Hem Toptan Hem Perakende Ticaret</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Sıfır Sipariş Komisyonu!</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Güçlü ve Doğru Müşteri Desteği</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Kargo ve Tedarik Entegrasyonları</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Türkiye Ödeme Sistemleri Entegrasyonları (iyzico, PayTR) </h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Muhasebe Sistemleri Entegrasyonları (Mikro, Logo)</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Müşteri İlişkileri Yönetimi (CRM) Entegrasyonları</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Küçük, Orta ve Büyük Ölçekli İşletmeler İçin</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Gelişkin ve Detaylı Ürün Seçenekleri</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Hazır Ödeme Yöntemleri</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">PCI DSS Uyumlu Sistem </h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 feature-row">
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                    <h6 class="flex-grow-1 m-0">Sürekli Güncellenen Güçlü Altyapı (nopCommerce)</h6>
                    <div class="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success">
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<app-cta></app-cta>
