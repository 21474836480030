<div data-overlay="" class="min-vh-100 bg-light d-flex flex-column">
  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-6 col-md-6">
          <div class="card card-body bg-light">
            <img class="icon icon-lg" src="assets/img/icons/theme/code/time-schedule.svg" alt="Icon" />
            <h1 class="h2 text-center">Çıkış yapılıyor...</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
