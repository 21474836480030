<div data-overlay class="min-vh-100 bg-light d-flex flex-column justify-content-md-center o-hidden">
    <section class="py-3">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
            <div class="card card-body shadow">
              <h1 class="h5 text-center">Ücretsiz Hesap Oluştur</h1>
              <form [formGroup]="signupForm" (ngSubmit)="onSignup()">
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="customerName" name="customerName" placeholder="Adınız" >
                </div>
                <div class="form-group">
                  <input type="email" class="form-control" formControlName="email" name="email" placeholder="Eposta adresiniz">
                </div>
                <div class="form-group">
                  <input type="password" class="form-control" name="password" formControlName="password" placeholder="Şifre giriniz">
                  <small *ngIf="password?.errors?.strength && password?.dirty">
                    Şifreniz en az 8 karakterden oluşmalı ve bir sayı içermelidir.
                  </small>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control" name="confirmPassword" formControlName="confirmPassword" placeholder="Şifre tekrarını giriniz">
                  <small *ngIf="passwordConfirm?.errors?.match">
                    Şifreler birbirinin aynısı olmalıdır .
                  </small>
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox text-small">
                    <input type="checkbox" class="custom-control-input" name="agree" formControlName="agree" id="sign-up-agree">
                    <label class="custom-control-label" for="sign-up-agree"><a target="_blank" href="utility-legal-terms.html">Üyelik ve kullanım sözleşmesini </a> okudum ve kabul ettim.
                    </label>
                    <small *ngIf="agree?.errors?.notAccepted || (agree?.errors?.required && agree?.dirty)">
                        Sözleşme kabul edilmelidir.
                    </small>
                  </div>
                </div>
                <button class="btn btn-primary btn-block" [disabled]="!signupForm.valid" type="submit">Hesap Oluştur</button>
              </form>
            </div>
            <div class="text-center text-small mt-3">
              Hesabınız varsa <a routerLink="/giris">buradan giriş yapın.</a>
            </div>
          </div>
          <div class="col-md-6 col-lg-auto ml-lg-5 ">
            <div class="mb-5">
              <div class="mb-2 mb-md-4" data-aos="fade-left" data-aos-delay="100">
                <div class="media rounded align-items-center pl-3 pr-3 pr-md-4 py-2 bg-white shadow-sm">
                  <img src="assets/img/avatars/male-4.jpg" alt="Harvey Derwent avatar image" class="avatar avatar-sm flex-shrink-0 mr-3">
                  <div class="text-dark mb-0">&ldquo;Uzun süre araştırdıktan sonra Kudyu ile satışa hemen başladım.&rdquo;</div>
                </div>
              </div>
              <div class="mb-2 mb-md-4" data-aos="fade-left" data-aos-delay="200">
                <div class="media rounded align-items-center pl-3 pr-3 pr-md-4 py-2 bg-white shadow-sm">
                  <img src="assets/img/avatars/male-1.jpg" alt="Harvey Derwent avatar image" class="avatar avatar-sm flex-shrink-0 mr-3">
                  <div class="text-dark mb-0">&ldquo;Kudyu'nun kullanım kolaylığı eşsiz.&rdquo;</div>
                </div>
              </div>
            </div>
            <div>
              <h2>Kudyu E-Ticaret ile</h2>
              <div class="mb-3" data-aos="fade-left" data-aos-delay="300">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-success-alt">
                    <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs bg-success" data-inject-svg>
                  </div>
                  <h6 class="mb-0 ml-3">30 Gün Ücretsiz Deneyin</h6>
                </div>
              </div>
              <div class="mb-3" data-aos="fade-left" data-aos-delay="400">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-success-alt">
                    <img src="assets/img/icons/interface/icon-check.svg" alt="Layouts icon" class="m-2 icon icon-xs bg-success" data-inject-svg>
                  </div>
                  <h6 class="mb-0 ml-3">Hazır ödeme arayüzüyle satışa hazır olun</h6>
                </div>
              </div>
              <div class="mb-3" data-aos="fade-left" data-aos-delay="500">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-success-alt">
                    <img src="assets/img/icons/interface/icon-check.svg" alt="Box icon" class="m-2 icon icon-xs bg-success" data-inject-svg>
                  </div>
                  <h6 class="mb-0 ml-3">Özgürce geliştirin, değiştirin</h6>
                </div>
              </div>
              <div class="mb-3" data-aos="fade-left" data-aos-delay="600">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-success-alt">
                    <img src="assets/img/icons/interface/icon-check.svg" alt="Lightning icon" class="m-2 icon icon-xs bg-success" data-inject-svg>
                  </div>
                  <h6 class="mb-0 ml-3">İşiniz büyüdükçe büyütün, daha çok müşteriye ulaşın</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>