import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.sass']
})
export class SignoutComponent implements OnInit {

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.clearStorage();
    window.location.replace("/");
    //TODO: Later, refactor this to navigate main route and 
    // update auth service to emit an event for subscribers to
    // take necessary actions e.g changing buttons and UI...
    //this.router.navigate([''])

  }

}
