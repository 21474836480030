<div
  data-overlay=""
  class="min-vh-100 bg-light d-flex flex-column"
>
  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-6 col-md-6">
          <div class="card card-body bg-light ">
            <img class="icon bg-primary" src="assets/img/icons/theme/general/like.svg" alt="Icon" data-inject-svg />
            <h1 class="h2 text-center">Epostanız Onaylandı.</h1>
            <p class="text-center">Artık site oluşturabilir ve eticarete başlayabilirsiniz.</p>
            <a *ngIf="!loggedIn" class="btn btn-primary" routerLink="/signin">
              Giriş Yap
            </a>
            <a *ngIf="loggedIn" class="btn btn-outline-primary" routerLink="/account">
                <img class="icon bg-primary" src="assets/img/icons/theme/shopping/box-3.svg" alt="Icon" data-inject-svg />
                Site Ekle                
            </a>
          </div>
          <div class="text-center text-small mt-3">
            Bilgilerinizi mi unuttunuz?
            <a href="account-sign-in-simple.html">Parolanızı sıfırlayın</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
