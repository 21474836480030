import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-verifyacc',
  templateUrl: './verifyacc.component.html',
  styleUrls: ['./verifyacc.component.sass']
})
export class VerifyaccComponent implements OnInit {

  @Input()
  verified: boolean = false;

  sendAgainEnabled: boolean = true;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
  }

  onSendConf(): void {
    this.api.postTypeRequest("/account/sendactivation", {}).subscribe((res: any) => {
      this.sendAgainEnabled = false;
    });
  }

}
