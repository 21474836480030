<section class="bg-primary-3 text-white p-0 o-hidden">
  <div class="container min-vh-100 d-flex flex-column justify-content-between text-center py-4 py-md-5">
    <div class="my-5">
      <div class="row justify-content-center">
        <div class="col-5 mb-4">
          <img src="assets/img/illustrations/illustration-4.svg" alt="Sayfa Bulunamadı" class="img-fluid">
        </div>
        <div class="col-12">
          <h1>404 - Sayfa Bulunamadı</h1>
          <div class="lead">Aradığınız sayfa bulunamadı.</div>
        </div>
      </div>
    </div>
    <div>
      <a href="/" class="btn btn-sm btn-outline-light fade-page">Anasayfa</a>
    </div>
  </div>
</section>
