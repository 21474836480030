<footer class="bg-primary-3 text-white links-white pb-4 footer-1">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-6 text-center text-lg-left">
                <a href="/">
                    <img src="/assets/img/logos/logo-white.svg" alt="Kudyu" width="150" data-inject-svg>
                </a>
            </div>
            <div class="col col-12 col-lg-6 d-flex justify-content-around justify-content-lg-end align-items-lg-center">
                <ul class="list-unstyled d-flex mb-0">
                    <li class="mx-1 mx-lg-3">
                        <a [routerLink]="['hakkimizda']" fragment="about-content" class="hover-fade-out">
              HAKKIMIZDA
            </a>
                    </li>
                    <li class="mx-1 mx-lg-3">
                        <a routerLink="ozellikler" fragment="feature-content" class="hover-fade-out">
              ÖZELLİKLER
            </a>
                    </li>
                    <li class="mx-1 mx-lg-3">
                        <a routerLink="yardim" fragment="help-content" class="hover-fade-out">
              YARDIM
            </a>
                    </li>
                    <li class="mx-1 mx-lg-3">
                        <a routerLink="iletisim" fragment="form-section" class="hover-fade-out">
              İLETİŞİM
            </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <hr>
            </div>
        </div>
        <div class="row flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
            <div class="col-auto">
                <div class="d-flex flex-column flex-sm-row align-items-center text-small">
                    <div class="text-muted">&copy;2024 Kudyu A.Ş. Tüm hakları saklıdır.
                    </div>
                </div>
            </div>
            <div class="col-auto mt-3 mt-lg-0">
                <ul class="list-unstyled d-flex mb-0">
                    <li class="mx-3">
                      <a href="https://www.instagram.com/kudyucommerce" class="hover-fade-out" target="_blank">
                        <img src="assets/img/icons/social/instagram.svg" width="30" alt="Kudyu Official Instagram">
                      </a>
                    </li>
                    <li class="mx-3">
                        <a href="https://www.nopcommerce.com/en/kudyu" class="hover-fade-out" target="_blank">
                            <img src="assets/img/nopCommerce-badge.png" width="123" alt="Nop Commerce - Kudyu Partnership">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
