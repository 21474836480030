<!--SLIDER-->
<div data-overlay class="bg-primary-3 jarallax text-white d-flex flex-column justify-content-center slider-main">
    <img src="assets/img/heros/hero-1.jpg" alt="Background" class="jarallax-img">
    <section class="pb-0 section-kudyu">
        <div class="container pb-5 mt-5 mt-lg-0">
            <div class="row justify-content-center text-center">
                <div class="col-xl-10 col-lg-10 col-md-11">
                    <h1 class="display-3 px-5 px-lg-0" data-aos="fade-up" data-aos-delay="100">
                      E-Ticarete en hızlı geçiş
                    </h1>
                    <p class="lead" data-aos="fade-up" data-aos-delay="200">
                        Kudyu Platformu ile alın, satın, ödeme kabul edin. İşinizi büyütün.
                    </p>
                    <div class="d-flex flex-column flex-sm-row justify-content-center mt-4 mt-md-5" data-aos="fade-up" data-aos-delay="300">
                        <a routerLink="/iletisim" fragment="form-section" class=" btn btn-primary btn-lg mx-sm-2 my-1 my-sm-0 ">HEMEN BAŞLAYIN</a>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="divider divider-bottom bg-white "></div>-->
    </section>
</div>
